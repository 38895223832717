




























import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      self: this,
      item: null,
      currentTab: "",
      permitted: false,
      tabs: {
        accounts: {
          label: "Tài khoản quảng cáo",
          condition() {
            return userManager.checkRole(["kt", "ksnb", "ksnbl", "mkt", "tp"]);
          },
        },
        currencies: {
          label: "Đơn vị tiền tệ",
          condition() {
            return userManager.checkRole(["kt", "ksnb", "ksnbl"]);
          },
        },
        balance_changes: {
          label: "Biến động số dư",
          condition() {
            return userManager.checkRole(["kt", "ksnb", "ksnbl"]);
          },
        },
        statistics: {
          label: "Thống kê",
          condition() {
            return userManager.checkRole(["kt", "ksnb", "ksnbl", "mkt", "tp"]);
          },
        },
      },
    };
  },
  async created() {
    this.item = await coreApiClient.call("adsAgencies", "findById", {
      id: this.$route.params.adsAgencyId,
    });
  },
});
